/**
 * External Dependencies
 */
import React, { Component } from 'react';
import './style.scss';
import classnames from 'classnames/dedupe';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
import { Collapse } from 'reactstrap';
import { Nav, NavItem } from 'reactstrap';
import Cookies from "js-cookie";
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';


/**
 * Internal Dependencies
 */
import '../../../../common-assets/js/yaybar/yaybar';
import { initPluginYaybar } from '../../../../common-assets/js/rootui-parts/initPluginYaybar';
import Dropdown from '../bs-dropdown';
import Icon from '../icon';
import { Link, NavLink } from 'react-router-dom';
// import { updateAuth as actionUpdateAuth } from '../../actions';

window.RootUI.initPluginYaybar = initPluginYaybar;
/**
 * Internal Dependencies
 */
import {
    updateAuth as actionUpdateAuth,
    updateSettings as actionUpdateSettings,
} from '../../actions';
import { initNavbar } from '../../../../common-assets/js/rootui-parts/initNavbar';
const $ = window.jQuery;
// const nav_height = document.querySelector('.rui-navbar-sticky').offsetHeight;

window.RootUI.initNavbar = initNavbar;
// const s_k_g = window.innerWidth;
// const nav_height = document.querySelector('.rui-navbar-sticky').innerHeight;
const s_k_g = window.innerWidth;
var height2 = window.innerHeight;
var wd = window.innerWidth;
var wid = wd / 2;
// var height1=nav_height
/**
 * Component
 */

if (Cookies.get('token2') == undefined || Cookies.get('token2') == "") {
    var emp_data = "";
}else{
    var emp_data = Cookies.get('token2');
    var emp_data2 = JSON.parse(emp_data);
    var emp_id = emp_data2["data"][0]["id"];
}
//console.log("emp_id====222222222222",emp_id);

//  var emp_data = Cookies.get('token2');
//  var emp_data2 = JSON.parse(emp_data);
//  const emp_id = "110";
//  const emp_id = emp_data2["data"][0]["id"];
//  //console.log("logiin_datatatattatata", emp_id);

class PageNavbar extends Component {
    constructor(props) {
        super(props);

        window.RootUI.initNavbar();

        this.state = {
            mobileMenuShow: false,
            mobileMenuShow2: 'none',
            toggleEmojii: false,
            show_logout_model: false,
            emp_id_new: emp_id,
            login_instruction_array: [], 
            logout_instruction_array: [],
        };
        this.renderSubmenus = this.renderSubmenus.bind(this);
        this.toogle = this.toogle.bind(this);
        this.logOut = this.logOut.bind(this);
        this.display_logout_model = this.display_logout_model.bind(this);
        this.opensidebar = this.opensidebar.bind(this);
        this.closesidebar = this.closesidebar.bind(this);
        // this.renderSubmenus = this.renderSubmenus.bind( this );
        // this.renderRightMenuItems = this.renderRightMenuItems.bind( this );
        this.toggleEmojii = this.toggleEmojii.bind( this );
        this.add_log_out_mood = this.add_log_out_mood.bind( this );

    }
    toggleEmojii() {
        this.setState( ( prevState ) => ( {
            toggleEmojii: true,
        } ) );
    }
    toogle() {
        this.setState((prevState) => ({
            mobileMenuShow: !prevState.mobileMenuShow,
            mobileMenuShow2: 'none'
        }));

    }

    componentDidMount() {
        this.fetch_all_logout_instruction()
      }

    

        fetch_all_logout_instruction() {
            var params = {
                mode: "fetch_all_logout_instruction"
            }
            var data = null;
            const { settings } = this.props;
            const res = fetch(settings.api_url, {
                method: 'POST',
                body: JSON.stringify(params),
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                }
            }).then((response) => response.json())
                .then(json => {
                    //console.log("Fetch Logout Response", json)
                    data = json;
                    if (data.status == true) {
                        this.setState({ 
                            logout_instruction_array: data["data"], 
                         });
                    } else {
                        this.setState({ 
                            logout_instruction_array: [], 
                        });
                    }
    
                })
            }
 

    

    display_logout_model=()=> {
      var emp_id_new = Cookies.get('employee_ki_id')
        const params = {
            mode: "display_logout_model",
            emp_id: emp_id_new
        }
        // console.log("LOGOUT Model--------------", params);
        var data = null;
        const { settings } = this.props;
        const res = fetch(settings.api_url, {
            method: 'POST',
            body: JSON.stringify(params),
            headers: {
                "Content-type": "application/json; charset=UTF-8",
            }
        })
            .then((response) => response.json()).then(json => {
                //console.log(json);
                data = json;
            }).then(() => {
                if (data.status == true) {
                    // console.log("LOGOUT Model Dispaly Response @@@@@@@@@@@@@@@2222##", data);
                    this.setState({
                        show_logout_model:data.show_logout_model
                    })

                    this.switch_log_out()
                } else {
                   
                    //console.log("no response");
                }
            });
    }

    switch_log_out(){
        console.log("show_logout_model",this.state.show_logout_model);
        if (this.state.show_logout_model == true) {
            //console.log("show_logout_model*************true");
            this.toggleEmojii()
        }else{
            //console.log("show_logout_model*************false");
            // this.logOut()
            this.logoutFun()
        }
    }

    add_log_out_mood() {
        var emp_id_new = Cookies.get('employee_ki_id')
        const params = {
            mode: "add_log_out_mood",
            emp_id:emp_id_new,
            logged_out_mood: this.state.emoji_data
        }
        //console.log("LogOUT ADd Mode", params);
        var data = null;
        const { settings,addToast } = this.props;
        const res = fetch(settings.api_url, {
            method: 'POST',
            body: JSON.stringify(params),
            headers: {
                "Content-type": "application/json; charset=UTF-8",
            }
        })
            .then((response) => response.json()).then(json => {
                //console.log(json);
                data = json;
            }).then(() => {
                //console.log("Logout datata Mode Response @@@@@@@@@@@@@@@2222##", data);
                if (data.status == true) {
                    this.logoutFun()
                   this.setState({
                    toggleEmojii:false
                   })
                 

                } else {
                    this.setState({
                        toggleEmojii:true
                       })
                     
                    // //console.log("no response");
                }

            });
    }
    renderSubmenus(nav, returnObject = false) {
        let thereIsActive = false;

        const result = Object.keys(nav).map((url) => {
            const data = nav[url];
            const isActive = window.location.hash === `#${url}`;
            let isOpened = false;

            if (isActive) {
                thereIsActive = true;
            }

            let sub = '';
            if (data.sub) {
                const subData = this.renderSubmenus(data.sub, true);

                sub = (
                    <ul className="yay-submenu dropdown-triangle">
                        {subData.content}
                    </ul>
                );

                if (subData.thereIsActive) {
                    isOpened = true;
                    thereIsActive = true;
                }
            }

            return (
                <React.Fragment key={`${url}-${data.name}`}>
                    {data.label ? (
                        <li className="yay-label">{data.label}</li>
                    ) : ''}
                    <li className={classnames({
                        'yay-item-active': isActive,
                        'yay-submenu-open': isOpened,
                    })}>
                        {data.name ? (
                            <NavLink
                                to={data.sub ? '#' : url}
                                className={data.sub ? 'yay-sub-toggle' : ''}
                            >
                                {data.icon ? (
                                    <>
                                        <span className="yay-icon">
                                            <Icon name={data.icon} />
                                        </span>
                                        <span>{data.name}</span>
                                        <span className="rui-yaybar-circle" />
                                    </>
                                ) : (
                                    data.name
                                )}
                                {data.sub ? (
                                    <span className="yay-icon-collapse">
                                        <Icon name="chevron-right" strokeWidth="1" className="rui-icon-collapse" />
                                    </span>
                                ) : ''}
                            </NavLink>
                        ) : ''}
                        {sub}
                    </li>
                </React.Fragment>
            );
        });

        if (returnObject) {
            return {
                content: result,
                thereIsActive,
            };
        }

        return result;
    }
    // componentDidMount() {
    //     // window.RootUI.initPluginYaybar();
    //     $( document ).on( 'keydown.rui-navbar', ( e ) => {
    //         const {
    //             mobileMenuShow,
    //         } = this.state;

    //         if ( mobileMenuShow && e.keyCode === 27 ) {
    //             this.setState( {
    //                 mobileMenuShow: ! mobileMenuShow,
    //             } );
    //         }
    //     } );
    // }

    componentWillUnmount() {
        $(document).off('keydown.rui-navbar');
    }

    logoutFun = () => {
        var emp_id_new = Cookies.get('employee_ki_id')
        var device_id = Cookies.get('device_id')
        //console.log("this.state.emp_id_new",this.state.emp_id_new);
        const { settings} = this.props;
        var params = {
            mode: "logout",
            emp_id: emp_id_new,
            device_id : device_id
        }
        console.log("Logout Api Params==========", params);
        const res = fetch(settings.api_url, {
          
            method: 'POST',
            body: JSON.stringify(params),
            headers: {
                "Content-type": "application/json; charset=UTF-8",
            }
          
        }).then((response) => response.json())
            .then(json => {
                console.log("Logout data Response **************************************", { response: json })
                var data = json;
                 this.logOut();
            })
           
            // .then(json => {
            //     //console.log("Logout data Response **************************************", { response: json });
            //     // Call logOut() after successful API response
            //     // this.logOut();
            // })
    }

   


    logOut() {
        // this.toggleEmojii()
        //console.log("LAGOUTTTTTTTTTTTTTNEWWWW");
        const {
            updateAuth,
        } = this.props;

        updateAuth({
            token2: '',
        });
        Cookies.remove("employee_ki_id")
        Cookies.remove("token2")
        Cookies.remove("device_id")
        // this.logoutFun();
        // //console.log("logoutFun response=",this.logoutFun);
        
    }

    // renderSubmenus( nav, isMobile, level = 1 ) {
    //     return Object.keys( nav ).map( ( url ) => {
    //         const data = nav[ url ];

    //         const isActive = window.location.hash === `#${ url }`;

    //         const LinkContent = data.name ? (
    //             <>
    //                 { data.icon ? (
    //                     <>
    //                         <Icon name={ data.icon } />
    //                         <span>{ data.name }</span>
    //                         <span className={ data.sub ? 'rui-dropdown-circle' : 'rui-nav-circle' } />
    //                     </>
    //                 ) : (
    //                     data.name
    //                 ) }
    //             </>
    //         ) : '';

    //         return (
    //             <React.Fragment key={ `${ url }-${ data.name }` }>
    //                 { data.sub ? (
    //                     <Dropdown tag="li" className={ classnames( isActive ? 'active' : '' ) } direction={ level === 1 ? 'up' : 'right' } openOnHover={ ! isMobile } showTriangle>
    //                         <Dropdown.Toggle tag="a"  className="dropdown-item">
    //                             { LinkContent }
    //                         </Dropdown.Toggle>
    //                         <Dropdown.Menu tag="ul" className="nav dropdown-menu">
    //                             { this.renderSubmenus( data.sub, isMobile, level + 1 ) }
    //                         </Dropdown.Menu>
    //                     </Dropdown>
    //                 ) : (
    //                     <li className={ classnames( 'nav-item', isActive ? 'active' : '' ) }>
    //                         <Link
    //                             to={ data.sub ? '#' : url }
    //                             className="nav-link"
    //                         >
    //                             { LinkContent }
    //                         </Link>
    //                     </li>
    //                 ) }
    //             </React.Fragment>
    //         );
    //     } );
    // }

    // renderRightMenuItems( isMobile ) {
    //     const {
    //         settings,
    //         updateSettings,
    //     } = this.props;

    //     const countriesDropdown = [
    //         {
    //             name: 'USA',
    //             img: settings.img_country.usa,
    //         },
    //         {
    //             name: 'China',
    //             img: settings.img_country.china,
    //         },
    //         {
    //             name: 'Germany',
    //             img: settings.img_country.germany,
    //         },
    //         {
    //             name: 'Japan',
    //             img: settings.img_country.japan,
    //         },
    //         {
    //             name: 'Spain',
    //             img: settings.img_country.spain,
    //         },
    //     ];

    //     return (
    //         <>
    //             {/* <li className="nav-item">
    //                 <FancyBox
    //                     tagName="a"
    //                     className={
    //                         classnames(
    //                             'd-flex',
    //                             isMobile ? 'nav-link' : ''
    //                         )
    //                     }
    //                     touch={ false }
    //                     closeExisting
    //                     autoFocus
    //                     parentEl={ null }
    //                     popupClassName="rui-popup rui-popup-search container"
    //                     popupRender={ () => (
    //                         <PagePopupSearch settings={ settings } />
    //                     ) }
    //                 >
    //                     { isMobile ? (
    //                         <>
    //                             <Icon name="search" />
    //                             <span>Search</span>
    //                         </>
    //                     ) : (
    //                         <span className="btn btn-custom-round">
    //                             <Icon name="search" />
    //                         </span>
    //                     ) }
    //                 </FancyBox>
    //             </li> */}
    //             <Dropdown tag="li" direction="up" openOnHover={ ! isMobile } showTriangle>
    //                 <Dropdown.Toggle
    //                     tag="a"
    //                     href="#"
    //                     className={
    //                         classnames(
    //                             'dropdown-item',
    //                             isMobile ? 'nav-link' : ''
    //                         )
    //                     }
    //                 >
    //                     { isMobile ? (
    //                         <>
    //                             <Icon name="flag" />
    //                             <span>Language</span>
    //                         </>
    //                     ) : (
    //                         <span className="btn btn-custom-round">
    //                             <Icon name="flag" />
    //                         </span>
    //                     ) }
    //                 </Dropdown.Toggle>
    //                 <Dropdown.Menu tag="ul" className="nav dropdown-menu rui-navbar-dropdown-language" modifiers={ { offset: { offset: '0,12' } } }>
    //                     { countriesDropdown.map( ( data ) => (
    //                         <li key={ data.name }>
    //                             <Link to="#" className="rui-navbar-language">
    //                                 <span className="rui-navbar-language-img">
    //                                     <img src={ data.img } alt={ data.name } />
    //                                 </span>
    //                                 { data.name }
    //                             </Link>
    //                         </li>
    //                     ) ) }
    //                 </Dropdown.Menu>
    //             </Dropdown>
    //             <Dropdown tag="li" direction="up" openOnHover={ ! isMobile } showTriangle>
    //                 <Dropdown.Toggle
    //                     tag="a"
    //                     href="#"
    //                     className={
    //                         classnames(
    //                             'dropdown-item',
    //                             isMobile ? 'nav-link' : ''
    //                         )
    //                     }
    //                 >
    //                     { isMobile ? (
    //                         <>
    //                             <Icon name="bell" />
    //                             <span>Notifications</span>
    //                             <span className="badge badge-circle badge-brand">3</span>
    //                         </>
    //                     ) : (
    //                         <span className="btn btn-custom-round">
    //                             <Icon name="bell" className="mr-0" />
    //                             <span className="badge badge-circle badge-brand">3</span>
    //                         </span>
    //                     ) }
    //                 </Dropdown.Toggle>
    //                 <Dropdown.Menu tag="ul" className="nav dropdown-menu rui-navbar-dropdown-notice" modifiers={ { offset: { offset: '0,12' } } }>
    //                     <li className="rui-navbar-dropdown-title mb-10">
    //                         <div className="d-flex align-items-center">
    //                             <h2 className="h4 mb-0 mr-auto">Notifications</h2>
    //                             <Link to="/profile" className="btn btn-custom-round">
    //                                 <Icon name="link2" />
    //                             </Link>
    //                         </div>
    //                     </li>
    //                     <li>
    //                         <div className="media media-success media-filled mnl-30 mnr-30">
    //                             <Link to="/profile" className="media-link">
    //                                 <span className="media-img"><img src={ settings.users[ 4 ].img } alt="" /></span>
    //                                 <span className="media-body">
    //                                     <span className="media-title">{ settings.users[ 4 ].name }</span>
    //                                     <small className="media-subtitle">Bring abundantly creature great...</small>
    //                                 </span>
    //                             </Link>
    //                             <Link to="#" className="media-icon">
    //                                 <Icon name="x" />
    //                             </Link>
    //                         </div>
    //                     </li>
    //                     <li>
    //                         <div className="media media-filled mnl-30 mnr-30">
    //                             <Link to="/profile" className="media-link">
    //                                 <span className="media-img">C</span>
    //                                 <span className="media-body">
    //                                     <span className="media-title">Change Design</span>
    //                                     <small className="media-subtitle">Design</small>
    //                                 </span>
    //                             </Link>
    //                             <Link to="#" className="media-icon">
    //                                 <Icon name="x" />
    //                             </Link>
    //                         </div>
    //                     </li>
    //                     <li>
    //                         <div className="media media-filled mnl-30 mnr-30">
    //                             <Link to="/profile" className="media-link">
    //                                 <span className="media-img bg-transparent"><img src={ settings.img_file.zip } className="icon-file" alt="" /></span>
    //                                 <span className="media-body">
    //                                     <span className="media-title">Added banner archive</span>
    //                                     <small className="media-subtitle">Commerce</small>
    //                                 </span>
    //                             </Link>
    //                             <Link to="#" className="media-icon">
    //                                 <Icon name="x" />
    //                             </Link>
    //                         </div>
    //                     </li>
    //                 </Dropdown.Menu>
    //             </Dropdown>
    //             {/* <li className="nav-item">
    //                 <FancyBox
    //                     tagName="a"
    //                     className={
    //                         classnames(
    //                             'd-flex',
    //                             isMobile ? 'nav-link' : ''
    //                         )
    //                     }
    //                     keyboard={ false }
    //                     touch={ false }
    //                     closeExisting
    //                     autoFocus
    //                     popupClassName="rui-popup rui-popup-messenger"
    //                     popupRender={ () => (
    //                         <Messenger settings={ settings } />
    //                     ) }
    //                 >
    //                     { isMobile ? (
    //                         <>
    //                             <Icon name="message-circle" />
    //                             <span>Messenger</span>
    //                         </>
    //                     ) : (
    //                         <span className="btn btn-custom-round">
    //                             <Icon name="message-circle" />
    //                         </span>
    //                     ) }
    //                 </FancyBox>
    //             </li> */}
    //             { ! isMobile ? (
    //                 <Dropdown tag="li" direction="up" openOnHover showTriangle>
    //                     <Dropdown.Toggle tag="a" href="#" className="dropdown-item rui-navbar-avatar mnr-6">
    //                         <img src={ settings.users[ 0 ].img } alt="" />
    //                     </Dropdown.Toggle>
    //                     <Dropdown.Menu tag="ul" className="nav dropdown-menu">
    //                         <li>
    //                             <Link to="/profile" className="nav-link">
    //                                 <Icon name="plus-circle" />
    //                                 <span>Create new Post</span>
    //                                 <span className="rui-nav-circle" />
    //                             </Link>
    //                         </li>
    //                         <li>
    //                             <Link to="/profile" className="nav-link">
    //                                 <Icon name="users" />
    //                                 <span>Manage Users</span>
    //                                 <span className="rui-nav-circle" />
    //                             </Link>
    //                         </li>
    //                         <li>
    //                             <Link to="/profile" className="nav-link">
    //                                 <Icon name="check-circle" />
    //                                 <span>Check Updates</span>
    //                                 <span className="rui-nav-circle" />
    //                             </Link>
    //                         </li>
    //                         <li>
    //                             <Link
    //                                 to="#"
    //                                 className="nav-link"
    //                                 onClick={ this.logOut }
    //                             >
    //                                 <Icon name="log-out" />
    //                                 <span>Log Out</span>
    //                                 <span className="rui-nav-circle" />
    //                             </Link>
    //                         </li>
    //                     </Dropdown.Menu>
    //                 </Dropdown>
    //             ) : '' }
    //             <Dropdown tag="li" direction="up" openOnHover={ ! isMobile } showTriangle>
    //                 <Dropdown.Toggle
    //                     tag="a"
    //                     href="#"
    //                     className={
    //                         classnames(
    //                             'dropdown-item mnr-5',
    //                             isMobile ? 'nav-link' : ''
    //                         )
    //                     }
    //                     nav-link
    //                 >
    //                     { isMobile ? (
    //                         <>
    //                             <Icon name="more-vertical" />
    //                             <span>More</span>
    //                             <span className="rui-dropdown-circle" />
    //                         </>
    //                     ) : (
    //                         <span className="btn btn-custom-round">
    //                             <Icon name="more-vertical" />
    //                         </span>
    //                     ) }
    //                 </Dropdown.Toggle>
    //                 <Dropdown.Menu tag="ul" className="nav dropdown-menu" modifiers={ { offset: { offset: '0,12' } } }>
    //                     <li>
    //                         <div className="custom-control custom-switch dropdown-item-switch">
    //                             <input
    //                                 type="checkbox"
    //                                 className="custom-control-input"
    //                                 id="toggleNightMode"
    //                                 checked={ settings.night_mode }
    //                                 onChange={ () => {
    //                                     updateSettings( {
    //                                         night_mode: ! settings.night_mode,
    //                                     } );
    //                                 } }
    //                             />
    //                             { /* eslint-disable-next-line */ }
    //                             <label className="dropdown-item custom-control-label mylab" htmlFor="toggleNightMode">
    //                                 <Icon name="moon" />
    //                                 <span>Night Mode</span>
    //                                 <span className="rui-dropdown-circle" />
    //                             </label>
    //                         </div>
    //                     </li>
    //                     <li>
    //                         <div className="custom-control custom-switch dropdown-item-switch">
    //                             <input
    //                                 type="checkbox"
    //                                 className="custom-control-input"
    //                                 id="toggleSpotlightMode"
    //                                 checked={ settings.spotlight_mode }
    //                                 onChange={ () => {
    //                                     updateSettings( {
    //                                         spotlight_mode: ! settings.spotlight_mode,
    //                                     } );
    //                                 } }
    //                             />
    //                             { /* eslint-disable-next-line */ }
    //                             <label className="dropdown-item custom-control-label mylab" htmlFor="toggleSpotlightMode">
    //                                 <Icon name="square" />
    //                                 <span>Spotlight Mode</span>
    //                                 <span className="rui-dropdown-circle" />
    //                             </label>
    //                         </div>
    //                     </li>
    //                     <li>
    //                         <div className="custom-control custom-switch dropdown-item-switch">
    //                             <input
    //                                 type="checkbox"
    //                                 className="custom-control-input"
    //                                 id="toggleSectionLines"
    //                                 checked={ settings.show_section_lines }
    //                                 onChange={ () => {
    //                                     updateSettings( {
    //                                         show_section_lines: ! settings.show_section_lines,
    //                                     } );
    //                                 } }
    //                             />
    //                             { /* eslint-disable-next-line */ }
    //                             <label className="dropdown-item custom-control-label mylab" htmlFor="toggleSectionLines">
    //                                 <Icon name="layout" />
    //                                 <span>Show section lines</span>
    //                                 <span className="rui-dropdown-circle" />
    //                             </label>
    //                         </div>
    //                     </li>
    //                     <li className="dropdown-menu-label">Sidebar</li>
    //                     <li>
    //                         <div className="custom-control custom-switch dropdown-item-switch">
    //                             <input
    //                                 type="checkbox"
    //                                 className="custom-control-input"
    //                                 id="toggleDarkSidebar"
    //                                 checked={ settings.sidebar_dark }
    //                                 onChange={ () => {
    //                                     updateSettings( {
    //                                         sidebar_dark: ! settings.sidebar_dark,
    //                                     } );
    //                                 } }
    //                             />
    //                             { /* eslint-disable-next-line */ }
    //                             <label className="dropdown-item custom-control-label mylab" htmlFor="toggleDarkSidebar">
    //                                 <Icon name="sidebar" />
    //                                 <span>Dark</span>
    //                                 <span className="rui-dropdown-circle" />
    //                             </label>
    //                         </div>
    //                     </li>
    //                     <li>
    //                         <div className="custom-control custom-switch dropdown-item-switch">
    //                             <input
    //                                 type="checkbox"
    //                                 className="custom-control-input"
    //                                 id="toggleStaticSidebar"
    //                                 checked={ settings.sidebar_static }
    //                                 onChange={ () => {
    //                                     updateSettings( {
    //                                         sidebar_static: ! settings.sidebar_static,
    //                                     } );
    //                                 } }
    //                             />
    //                             { /* eslint-disable-next-line */ }
    //                             <label className="dropdown-item custom-control-label mylab" htmlFor="toggleStaticSidebar">
    //                                 <Icon name="sidebar" />
    //                                 <span>Static</span>
    //                                 <span className="rui-dropdown-circle" />
    //                             </label>
    //                         </div>
    //                     </li>
    //                     <li className="dropdown-menu-label">Navbar</li>
    //                     <li>
    //                         <div className="custom-control custom-switch dropdown-item-switch">
    //                             <input
    //                                 type="checkbox"
    //                                 className="custom-control-input"
    //                                 id="toggleDarkNavbar"
    //                                 checked={ settings.nav_dark }
    //                                 onChange={ () => {
    //                                     updateSettings( {
    //                                         nav_dark: ! settings.nav_dark,
    //                                     } );
    //                                 } }
    //                             />
    //                             { /* eslint-disable-next-line */ }
    //                             <label className="dropdown-item custom-control-label mylab" htmlFor="toggleDarkNavbar">
    //                                 <Icon name="menu" />
    //                                 <span>Dark</span>
    //                                 <span className="rui-dropdown-circle" />
    //                             </label>
    //                         </div>
    //                     </li>
    //                 </Dropdown.Menu>
    //             </Dropdown>
    //         </>
    //     );
    // }
    opensidebar() {
        if (s_k_g < 600) {
            document.getElementById("idname").style.width = "65%";
            this.setState({
                mobileMenuShow2: 'block',
                mobileMenuShow: false
            })


            document.getElementById("idname").style.boxShadow = "rgb(177, 174, 174) -7px 10px 10px 7px";
        }
        else {
            document.getElementById("idname").style.width = "60%";
            this.setState({
                mobileMenuShow2: 'block',
                mobileMenuShow: false
            })


            document.getElementById("idname").style.boxShadow = "rgb(233, 225, 225) 10px 10px 10px -4px"
        }

    }
    closesidebar() {
        document.getElementById("idname").style.width = "0";
        this.setState({
            mobileMenuShow2: 'block',
            mobileMenuShow: false
        })


        document.getElementById("idname").style.boxShadow = " none";
    }
    render() {

        var emp_data = Cookies.get('token2');
        //console.log("emp_data", emp_data);
        var emp_data2 = JSON.parse(emp_data);
        //   const emp_id = emp_data2["data"][0]["id"];
        if (emp_data2 == "" || emp_data2 == undefined) {
            //console.log("No cookies");
        } else {
            if (emp_data2["data"][0]["type"] == undefined) {
                var type2 = '';
                //console.log("emp_type$$$$$$$$$$$$$$$$$$$$$$$$$$$$$", type2);
            } else {
                var type2 = emp_data2["data"][0]["type"];
                //console.log("emp_type$$$$$$$$$$$$$$$$$$$$$$$$$$$$$", type2);
            }

            var Name = emp_data2["data"][0]["name"];
            //console.log("name", Name);
        }



        //      if(emp_data == "" || emp_data == undefined){
        //         //console.log("No cookies");
        //       }
        //       else{

        //       //console.log("sadasdasda", emp_data);
        //       //console.log("emp_data id", emp_data["data"]);
        //       var emp_data = JSON.parse(emp_data);
        //       const emp_id = emp_data["data"][0]["id"];
        //       var type = emp_data["data"][0]["type"];
        //       //console.log("emp_id", emp_id);
        //       //console.log("emp_type$$$$$$$$$$$$$$$$$$$$$$$$$$$$$", type);
        //       this.setState({
        //                      type: type
        //       });

        //     }
        const isActive = window.location.hash;
        //console.log("is active", isActive);
        const isssActive = window.location;
        //console.log("isssActiveisssActive", isssActive);
        const {
            settings,
            updateSettings,
        } = this.props;

        const {
            mobileMenuShow,
        } = this.state;


        return (
            <>
                { /* Nav Menu */}
                <nav className={
                    classnames(
                        'rui-navbar rui-navbar-top',
                        settings.nav_dark ? 'rui-navbar-dark' : '',
                        settings.nav_sticky ? 'rui-navbar-sticky' : '',
                        settings.nav_fixed ? 'rui-navbar-fixed' : '',
                        settings.nav_expand ? `rui-navbar-expand-${settings.nav_expand}` : '',
                    )
                }
                >
                    <div className="rui-navbar-brand">
                        {settings.nav_logo_path ? (
                            <span to={settings.nav_logo_url} className="rui-navbar-logo">
                                <span style={{display:'inline-flex'}}>
                                    <img src={settings.night_mode || settings.nav_dark ? settings.nav_logo_white_path : settings.nav_logo_path} alt=""height={23} width={23} style={{marginTop:'3px'}} />
                                    <h2 style={{marginBottom:'0px',marginLeft:'5px',marginTop:'3px'}}>HRM</h2>
                                </span>
                            </span>
                        ) : ''}

                        {/* <button className="yay-toggle rui-yaybar-toggle" type="button">
                            <span />
                        </button> */}
                    </div>
                    {/*  */}
                    {/* <div className={
                    classnames(
                        'yaybar rui-yaybar yay-hide-to-small yay-gestures',
                        settings.sidebar_dark && ! settings.night_mode ? 'rui-yaybar-dark' : '',
                        settings.sidebar_static ? 'yay-static' : '',
                        settings.sidebar_effect ? `yay-${ settings.sidebar_effect }` : '',
                    )
                }
                >
                    <div className="yay-wrap-menu">
                        <div className="yaybar-wrap">

                        </div>
                    </div>
                </div>
                <div className="rui-yaybar-bg" /> */}
                    {/*  */}
                    <div className={`container${settings.nav_container_fluid ? '-fluid' : ''}`}>
                        <div style={{ paddingTop: '20px' }} className="rui-navbar-content">
                            <div style={{ display: type2 == "employee" ? "block" : "none", width: '100%' }}>
                                <Nav>
                                    <NavItem className="ops employeeNavBar">
                                        <Link className={classnames(isActive == "#/home" ? 'isactive' : 'isnotactive')} to="/home">
                                            <Icon style={{ marginRight: '10px' }} name="home" />
                                            <span >Home</span>
                                        </Link>
                                    </NavItem>
                                    {/* <NavItem>
                                    <Link className={ classnames( isActive== "#/colors" ? 'isactive' : 'isnotactive' )} to="/colors">
                                        <Icon style={{marginRight:'10px'}} name="book" />
                                        <span>Master_employee</span>
                                    </Link>
                                </NavItem> */}

                                    <NavItem className="ops employeeNavBar">
                                        <Link className={classnames(isActive == "#/employee-performance" ? 'isactive' : 'isnotactive')} to="/employee-performance" >
                                            <Icon style={{ marginRight: '10px' }} name="grid" />
                                            <span>Performance</span>
                                        </Link>
                                    </NavItem>
                                    {/* <NavItem className="ops employeeNavBar">
                                        <Link className={classnames(isActive == "#/assign-task" ? 'isactive' : 'isnotactive')} to="/assign-task" >
                                            <Icon style={{ marginRight: '10px' }} name="command" />
                                            <span>Assign Task</span>
                                        </Link>
                                    </NavItem> */}
                                    <NavItem className="ops employeeNavBar">
                                        <Link className={classnames(isActive == "#/payslip" ? 'isactive' : 'isnotactive')} to="/payslip" >
                                            <Icon style={{ marginRight: '10px' }} name="dollar-sign" />
                                            <span>Payslip</span>
                                        </Link>
                                    </NavItem>
                                    <NavItem className="ops employeeNavBar">
                                        <Link className={classnames(isActive == "#/my-deatils" ? 'isactive' : 'isnotactive')} to="/my-deatils" >
                                            <Icon style={{ marginRight: '10px' }} name="book" />
                                            <span>My Details</span>
                                        </Link>
                                    </NavItem>
                                    {/* <NavItem style={{marginLeft:'auto'}}>

                                    </NavItem> */}
                                    <NavItem className="log">
                                        <h2>{Name}</h2>
                                        <div className="lof" onClick={this.display_logout_model} >
                                        {/* <Link className={classnames(isActive == "#/sign-in" ? 'isactive' : 'isnotactive'), "lof"} onClick={this.logOut} to="/sign-in" > */}
                                            <Icon style={{ marginRight: '10px' }} name="log-out" />
                                            <span>Log Out</span>
                                        </div>
                                    </NavItem>
                                    <NavItem className="dropss">
                                        <Dropdown
                                            tag="div"
                                            className="btn-group"
                                            openOnHover
                                            showTriangle
                                        >
                                            <Dropdown.Toggle tag="a" className="dropdown-item ">
                                                <span className="btn btn-custom-round">
                                                    <Icon name="more-vertical" />
                                                </span>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu tag="ul" className="nav sss">
                                                <li>
                                                    <div className="custom-control custom-switch dropdown-item-switch">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="toggleNightMode"
                                                            checked={settings.night_mode}
                                                            onChange={() => {
                                                                updateSettings({
                                                                    night_mode: !settings.night_mode,
                                                                });
                                                                //console.log("oooooooooooo", !settings.night_mode);
                                                                Cookies.set('night_mode', !settings.night_mode);
                                                                //console.log("Cookies.gt( 'night_mode')", Cookies.get('night_mode'));

                                                            }}
                                                        />

                                                        <label className="dropdown-item custom-control-label mylab" htmlFor="toggleNightMode">
                                                            <Icon name="moon" />
                                                            <span>Night Mode</span>
                                                            <span className="rui-dropdown-circle" />
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="custom-control custom-switch dropdown-item-switch">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="toggleSpotlightMode"
                                                            checked={settings.spotlight_mode}
                                                            onChange={() => {
                                                                updateSettings({
                                                                    spotlight_mode: !settings.spotlight_mode,
                                                                });
                                                            }}
                                                        />

                                                        <label className="dropdown-item custom-control-label mylab" htmlFor="toggleSpotlightMode">
                                                            <Icon name="square" />
                                                            <span>Spotlight Mode</span>
                                                            <span className="rui-dropdown-circle" />
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="custom-control custom-switch dropdown-item-switch">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="toggleSectionLines"
                                                            checked={settings.show_section_lines}
                                                            onChange={() => {
                                                                updateSettings({
                                                                    show_section_lines: !settings.show_section_lines,
                                                                });
                                                            }}
                                                        />

                                                        <label className="dropdown-item custom-control-label mylab" htmlFor="toggleSectionLines">
                                                            <Icon name="layout" />
                                                            <span>Show section lines</span>
                                                            <span className="rui-dropdown-circle" />
                                                        </label>
                                                    </div>
                                                </li>
                                                <li className="dropdown-menu-label">Sidebar</li>
                                                <li>
                                                    <div className="custom-control custom-switch dropdown-item-switch">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="toggleDarkSidebar"
                                                            checked={settings.sidebar_dark}
                                                            onChange={() => {
                                                                updateSettings({
                                                                    sidebar_dark: !settings.sidebar_dark,
                                                                });
                                                            }}
                                                        />

                                                        <label className="dropdown-item custom-control-label mylab" htmlFor="toggleDarkSidebar">
                                                            <Icon name="sidebar" />
                                                            <span>Dark</span>
                                                            <span className="rui-dropdown-circle" />
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="custom-control custom-switch dropdown-item-switch">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="toggleStaticSidebar"
                                                            checked={settings.sidebar_static}
                                                            onChange={() => {
                                                                updateSettings({
                                                                    sidebar_static: !settings.sidebar_static,
                                                                });
                                                            }}
                                                        />

                                                        <label className="dropdown-item custom-control-label mylab" htmlFor="toggleStaticSidebar">
                                                            <Icon name="sidebar" />
                                                            <span>Static</span>
                                                            <span className="rui-dropdown-circle" />
                                                        </label>
                                                    </div>
                                                </li>
                                                <li className="dropdown-menu-label">Navbar</li>
                                                <li>
                                                    <div className="custom-control custom-switch dropdown-item-switch">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="toggleDarkNavbar"
                                                            checked={settings.nav_dark}
                                                            onChange={() => {
                                                                updateSettings({
                                                                    nav_dark: !settings.nav_dark,
                                                                });
                                                            }}
                                                        />

                                                        <label className="dropdown-item custom-control-label mylab" htmlFor="toggleDarkNavbar">
                                                            <Icon name="menu" />
                                                            <span>Dark</span>
                                                            <span className="rui-dropdown-circle" />
                                                        </label>
                                                    </div>
                                                </li>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </NavItem>
                                </Nav>
                            </div>
                            <div style={{ display: type2 == "trainee" ? "block" : "none", width: '100%' }}>
                                <Nav>
                                    <NavItem className="ops employeeNavBar">
                                        <Link className={classnames(isActive == "#/home" ? 'isactive' : 'isnotactive')} to="/home">
                                            <Icon style={{ marginRight: '10px' }} name="home" />
                                            <span >Home</span>
                                        </Link>
                                    </NavItem>
                                    {/* <NavItem>
                                    <Link className={ classnames( isActive== "#/colors" ? 'isactive' : 'isnotactive' )} to="/colors">
                                        <Icon style={{marginRight:'10px'}} name="book" />
                                        <span>Master_employee</span>
                                    </Link>
                                </NavItem> */}

                                    <NavItem className="ops employeeNavBar">
                                        <Link className={classnames(isActive == "#/employee-performance" ? 'isactive' : 'isnotactive')} to="/employee-performance" >
                                            <Icon style={{ marginRight: '10px' }} name="grid" />
                                            <span>Performance</span>
                                        </Link>
                                    </NavItem>
                                    <NavItem className="ops employeeNavBar">
                                        <Link className={classnames(isActive == "#/payslip" ? 'isactive' : 'isnotactive')} to="/payslip" >
                                            <Icon style={{ marginRight: '10px' }} name="grid" />
                                            <span>Payslip</span>
                                        </Link>
                                    </NavItem>
                                    <NavItem className="ops employeeNavBar">
                                        <Link className={classnames(isActive == "#/my-deatils" ? 'isactive' : 'isnotactive')} to="/my-deatils" >
                                            <Icon style={{ marginRight: '10px' }} name="book" />
                                            <span>My Details</span>
                                        </Link>
                                    </NavItem>
                                    {/* <NavItem style={{marginLeft:'auto'}}>

                                    </NavItem> */}
                                    <NavItem className="log">
                                        <h2>{Name}</h2>
                                        <div className="lof" onClick={this.display_logout_model}  >
                                        {/* <Link className={classnames(isActive == "#/sign-in" ? 'isactive' : 'isnotactive'), "lof"} onClick={this.logOut}  to="/sign-in"> */}
                                            <Icon style={{ marginRight: '10px' }} name="log-out" />
                                            <span>Log Out</span>
                                        </div>
                                    </NavItem>
                                    <NavItem className="dropss">
                                        <Dropdown
                                            tag="div"
                                            className="btn-group"
                                            openOnHover
                                            showTriangle
                                        >
                                            <Dropdown.Toggle tag="a" className="dropdown-item ">
                                                <span className="btn btn-custom-round">
                                                    <Icon name="more-vertical" />
                                                </span>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu tag="ul" className="nav sss">
                                                <li>
                                                    <div className="custom-control custom-switch dropdown-item-switch">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="toggleNightMode"
                                                            checked={settings.night_mode}
                                                            onChange={() => {
                                                                updateSettings({
                                                                    night_mode: !settings.night_mode,
                                                                });
                                                                //console.log("oooooooooooo", !settings.night_mode);
                                                                Cookies.set('night_mode', !settings.night_mode);
                                                                //console.log("Cookies.gt( 'night_mode')", Cookies.get('night_mode'));

                                                            }}
                                                        />

                                                        <label className="dropdown-item custom-control-label mylab" htmlFor="toggleNightMode">
                                                            <Icon name="moon" />
                                                            <span>Night Mode</span>
                                                            <span className="rui-dropdown-circle" />
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="custom-control custom-switch dropdown-item-switch">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="toggleSpotlightMode"
                                                            checked={settings.spotlight_mode}
                                                            onChange={() => {
                                                                updateSettings({
                                                                    spotlight_mode: !settings.spotlight_mode,
                                                                });
                                                            }}
                                                        />

                                                        <label className="dropdown-item custom-control-label mylab" htmlFor="toggleSpotlightMode">
                                                            <Icon name="square" />
                                                            <span>Spotlight Mode</span>
                                                            <span className="rui-dropdown-circle" />
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="custom-control custom-switch dropdown-item-switch">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="toggleSectionLines"
                                                            checked={settings.show_section_lines}
                                                            onChange={() => {
                                                                updateSettings({
                                                                    show_section_lines: !settings.show_section_lines,
                                                                });
                                                            }}
                                                        />

                                                        <label className="dropdown-item custom-control-label mylab" htmlFor="toggleSectionLines">
                                                            <Icon name="layout" />
                                                            <span>Show section lines</span>
                                                            <span className="rui-dropdown-circle" />
                                                        </label>
                                                    </div>
                                                </li>
                                                <li className="dropdown-menu-label">Sidebar</li>
                                                <li>
                                                    <div className="custom-control custom-switch dropdown-item-switch">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="toggleDarkSidebar"
                                                            checked={settings.sidebar_dark}
                                                            onChange={() => {
                                                                updateSettings({
                                                                    sidebar_dark: !settings.sidebar_dark,
                                                                });
                                                            }}
                                                        />

                                                        <label className="dropdown-item custom-control-label mylab" htmlFor="toggleDarkSidebar">
                                                            <Icon name="sidebar" />
                                                            <span>Dark</span>
                                                            <span className="rui-dropdown-circle" />
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="custom-control custom-switch dropdown-item-switch">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="toggleStaticSidebar"
                                                            checked={settings.sidebar_static}
                                                            onChange={() => {
                                                                updateSettings({
                                                                    sidebar_static: !settings.sidebar_static,
                                                                });
                                                            }}
                                                        />

                                                        <label className="dropdown-item custom-control-label mylab" htmlFor="toggleStaticSidebar">
                                                            <Icon name="sidebar" />
                                                            <span>Static</span>
                                                            <span className="rui-dropdown-circle" />
                                                        </label>
                                                    </div>
                                                </li>
                                                <li className="dropdown-menu-label">Navbar</li>
                                                <li>
                                                    <div className="custom-control custom-switch dropdown-item-switch">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="toggleDarkNavbar"
                                                            checked={settings.nav_dark}
                                                            onChange={() => {
                                                                updateSettings({
                                                                    nav_dark: !settings.nav_dark,
                                                                });
                                                            }}
                                                        />

                                                        <label className="dropdown-item custom-control-label mylab" htmlFor="toggleDarkNavbar">
                                                            <Icon name="menu" />
                                                            <span>Dark</span>
                                                            <span className="rui-dropdown-circle" />
                                                        </label>
                                                    </div>
                                                </li>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </NavItem>
                                </Nav>
                            </div>
                            <div style={{ display: type2 == "admin" ? "block" : "none", width: '100%' }}>
                                <Nav >
                                    {/* <NavItem >
                                    <Link className={ classnames( isActive== "#/calendar" ? 'isactive' : 'isnotactive' )} to="/calendar">
                                        <Icon style={{marginRight:'10px'}} name="calendar" />
                                        <span>Calendar</span>
                            </Link>
                                </NavItem> */}

                                    <NavItem className="ops ksa">
                                        <Link className={classnames(isActive == "#/attendance" ? 'isactive' : 'isnotactive')} to="/attendance">
                                            <Icon style={{ marginRight: '10px' }} name="calendar" />
                                            <span>Attendance</span>
                                        </Link>
                                    </NavItem>

                                    <NavItem className="ops ksa" >
                                        <Link className={classnames(isActive == "#/performance" ? 'isactive' : 'isnotactive')} to="/performance">
                                            <Icon style={{ marginRight: '10px' }} name="grid" />
                                            <span>Performance</span>
                                        </Link>
                                    </NavItem>
                                    <NavItem className="ops ksa">
                                        <Link className={classnames(isActive == "#/master" ? 'isactive' : 'isnotactive')} to="/master">
                                            <Icon style={{ marginRight: '10px' }} name="sliders" />
                                            <span>Master</span>
                                        </Link>
                                    </NavItem>
                                    <NavItem className="ops ksa">
                                        <Link className={classnames(isActive == "#/suspended-employee" ? 'isactive' : 'isnotactive')} to="/suspended-employee" >
                                            <Icon style={{ marginRight: '10px' }} name="users" />
                                            <span>Suspended Employee</span>
                                        </Link>
                                    </NavItem>
                                    <NavItem className="log">
                                        <h2>{Name}</h2>
                                        <div className="lof" onClick={this.display_logout_model}  >
                                            <Icon style={{ marginRight: '10px' }} name="log-out" />
                                            <span>Log Out</span>
                                        </div>
                                    </NavItem>
                                    <NavItem className="dropss">
                                        <Dropdown
                                            tag="div"
                                            className="btn-group"
                                            openOnHover
                                            showTriangle
                                        >
                                            <Dropdown.Toggle tag="a" className="dropdown-item">
                                                <span className="btn btn-custom-round">
                                                    <Icon name="more-vertical" />
                                                </span>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu tag="ul" className="nav" modifiers={{ offset: { offset: '0,12' } }}>
                                                <li>
                                                    <div className="custom-control custom-switch dropdown-item-switch">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="toggleNightMode"
                                                            checked={settings.night_mode}
                                                            onChange={() => {
                                                                updateSettings({
                                                                    night_mode: !settings.night_mode,
                                                                });
                                                                //console.log("oooooooooooo", !settings.night_mode);
                                                                Cookies.set('night_mode_cookie', !settings.night_mode);
                                                                //console.log("Cookies.gt( 'night_mode_cookie')", Cookies.get('night_mode'));

                                                            }}
                                                        />

                                                        <label className="dropdown-item custom-control-label mylab" htmlFor="toggleNightMode">
                                                            <Icon name="moon" />
                                                            <span>Night Mode</span>
                                                            <span className="rui-dropdown-circle" />
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="custom-control custom-switch dropdown-item-switch">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="toggleSpotlightMode"
                                                            checked={settings.spotlight_mode}
                                                            onChange={() => {
                                                                updateSettings({
                                                                    spotlight_mode: !settings.spotlight_mode,
                                                                });
                                                            }}
                                                        />

                                                        <label className="dropdown-item custom-control-label mylab" htmlFor="toggleSpotlightMode">
                                                            <Icon name="square" />
                                                            <span>Spotlight Mode</span>
                                                            <span className="rui-dropdown-circle" />
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="custom-control custom-switch dropdown-item-switch">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="toggleSectionLines"
                                                            checked={settings.show_section_lines}
                                                            onChange={() => {
                                                                updateSettings({
                                                                    show_section_lines: !settings.show_section_lines,
                                                                });
                                                            }}
                                                        />

                                                        <label className="dropdown-item custom-control-label mylab" htmlFor="toggleSectionLines">
                                                            <Icon name="layout" />
                                                            <span>Show section lines</span>
                                                            <span className="rui-dropdown-circle" />
                                                        </label>
                                                    </div>
                                                </li>
                                                <li className="dropdown-menu-label">Sidebar</li>
                                                <li>
                                                    <div className="custom-control custom-switch dropdown-item-switch">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="toggleDarkSidebar"
                                                            checked={settings.sidebar_dark}
                                                            onChange={() => {
                                                                updateSettings({
                                                                    sidebar_dark: !settings.sidebar_dark,
                                                                });
                                                            }}
                                                        />

                                                        <label className="dropdown-item custom-control-label mylab" htmlFor="toggleDarkSidebar">
                                                            <Icon name="sidebar" />
                                                            <span>Dark</span>
                                                            <span className="rui-dropdown-circle" />
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="custom-control custom-switch dropdown-item-switch">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="toggleStaticSidebar"
                                                            checked={settings.sidebar_static}
                                                            onChange={() => {
                                                                updateSettings({
                                                                    sidebar_static: !settings.sidebar_static,
                                                                });
                                                            }}
                                                        />

                                                        <label className="dropdown-item custom-control-label mylab" htmlFor="toggleStaticSidebar">
                                                            <Icon name="sidebar" />
                                                            <span>Static</span>
                                                            <span className="rui-dropdown-circle" />
                                                        </label>
                                                    </div>
                                                </li>
                                                <li className="dropdown-menu-label">Navbar</li>
                                                <li>
                                                    <div className="custom-control custom-switch dropdown-item-switch">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="toggleDarkNavbar"
                                                            checked={settings.nav_dark}
                                                            onChange={() => {
                                                                updateSettings({
                                                                    nav_dark: !settings.nav_dark,
                                                                });
                                                            }}
                                                        />

                                                        <label className="dropdown-item custom-control-label mylab" htmlFor="toggleDarkNavbar">
                                                            <Icon name="menu" />
                                                            <span>Dark</span>
                                                            <span className="rui-dropdown-circle" />
                                                        </label>
                                                    </div>
                                                </li>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </NavItem>
                                </Nav>
                            </div>

                        </div>
                    </div>
                </nav>
                <div className="task_list2_122" id="idname" style={{ height: height2, width: wid, zIndex: '1009', top: "0px", display: this.state.mobileMenuShow2 }}>
                    <div style={{ marginTop: "0px" }}>
                        <div className="please" style={{ marginTop: "15px", padding: "0px 20px" }}>
                            <span className="closebtn" style={{ marginTop: "-2px", float: "right", fontSize: "20px" }} onClick={this.closesidebar} > &times;</span>
                        </div>
                        <div style={{ marginTop: "8px" }}>
                            <div className="card-body pt-20 pr-10 pb-20 pl-10" style={{ display: type2 == "employee" ? "block" : "none", width: '100%' }}>
                                <ul className="mynave">
                                    <li> <h2>{Name}</h2></li>
                                    <li className="myli"><Link className={classnames(isActive == "#/home" ? 'isactive' : 'isnotactive')} to="/home">
                                        <Icon style={{ marginRight: '10px' }} name="home" />
                                        <span >Home</span>
                                    </Link></li>
                                    <li className="myli"> <Link className={classnames(isActive == "#/employee-performance" ? 'isactive' : 'isnotactive')} to="/employee-performance" >
                                        <Icon style={{ marginRight: '10px' }} name="grid" />
                                        <span>Performance</span>
                                    </Link></li>
                                    {/* <li className="myli"> <Link className={classnames(isActive == "#/assign-task" ? 'isactive' : 'isnotactive')} to="/assign-task" >
                                        <Icon style={{ marginRight: '10px' }} name="command" />
                                        <span>Assign Task</span>
                                    </Link></li> */}
                                    <li className="myli"> <Link className={classnames(isActive == "#/payslip" ? 'isactive' : 'isnotactive')} to="/payslip" >
                                        <Icon style={{ marginRight: '10px' }} name="file" />
                                        <span>Payslip</span>
                                    </Link></li>
                                    <li className="myli"> <Link className={classnames(isActive == "#/my-deatils" ? 'isactive' : 'isnotactive')} to="/my-deatils" >
                                        <Icon style={{ marginRight: '10px' }} name="book" />
                                        <span>My Details</span>
                                    </Link></li>
                                    <li className="myli">  <div className={ "lof"} onClick={this.display_logout_model}  >
                                    {/* <li className="myli">  <Link className={ "lof"} onClick={this.logOut}  to="/sign-in"> */}
                                        <Icon style={{ marginRight: '10px' }} name="log-out" />
                                        <span>Log Out</span>
                                    </div></li>
                                </ul>
                            </div>
                            <div className="card-body pt-20 pr-10 pb-20 pl-10" style={{ display: type2 == "trainee" ? "block" : "none", width: '100%' }}>
                                <ul className="mynave">
                                    <li> <h2>{Name}</h2></li>
                                    <li className="myli"><Link className={classnames(isActive == "#/home" ? 'isactive' : 'isnotactive')} to="/home">
                                        <Icon style={{ marginRight: '10px' }} name="home" />
                                        <span >Home</span>
                                    </Link></li>
                                    <li className="myli"> <Link className={classnames(isActive == "#/employee-performance" ? 'isactive' : 'isnotactive')} to="/employee-performance" >
                                        <Icon style={{ marginRight: '10px' }} name="grid" />
                                        <span>Performance</span>
                                    </Link></li>
                                    <li className="myli"> <Link className={classnames(isActive == "#/payslip" ? 'isactive' : 'isnotactive')} to="/payslip" >
                                        <Icon style={{ marginRight: '10px' }} name="file" />
                                        <span>Payslip</span>
                                    </Link></li>
                                    <li className="myli"> <Link className={classnames(isActive == "#/my-deatils" ? 'isactive' : 'isnotactive')} to="/my-deatils" >
                                        <Icon style={{ marginRight: '10px' }} name="book" />
                                        <span>My Details</span>
                                    </Link></li>
                                    <li className="myli">

                                      <div className={ "lof"} onClick={this.display_logout_model} >
                                      {/* <Link className={ "lof"} onClick={this.logOut}  to="/sign-in"> */}
                                        <Icon style={{ marginRight: '10px' }} name="log-out" />
                                        <span>Log Out</span>
                                    </div></li>
                                </ul>
                            </div>
                            <div className="card-body pt-20 pr-10 pb-20 pl-10" style={{ display: type2 == "admin" ? "block" : "none", width: '100%' }}>
                                <ul className="mynave">
                                    <li><h2>{Name}</h2></li>
                                    <li className="myli">  <Link className={classnames(isActive == "#/attendance" ? 'isactive' : 'isnotactive')} to="/attendance">
                                        <Icon style={{ marginRight: '10px' }} name="calendar" />
                                        <span>Attendance</span>
                                    </Link></li>
                                    <li className="myli"> <Link className={classnames(isActive == "#/performance" ? 'isactive' : 'isnotactive')} to="/performance">
                                        <Icon style={{ marginRight: '10px' }} name="grid" />
                                        <span>Performance</span>
                                    </Link></li>
                                    <li className="myli">  <Link className={classnames(isActive == "#/master" ? 'isactive' : 'isnotactive')} to="/master">
                                        <Icon style={{ marginRight: '10px' }} name="sliders" />
                                        <span>Master</span>
                                    </Link></li>
                                    <li className="myli">  <Link className={classnames(isActive == "#/suspended-employee" ? 'isactive' : 'isnotactive')} to="/suspended-employee" >
                                        <Icon style={{ marginRight: '10px' }} name="users" />
                                        <span>Suspended Employee</span>
                                    </Link>
                                    </li>
                                    <li className="myli"> <div className={ "lof"} onClick={this.display_logout_model} >
                                    {/* <li className="myli"> <Link className={ "lof"} onClick={this.logOut}  to="/sign-in"> */}
                                        <Icon style={{ marginRight: '10px' }} name="log-out" />
                                        <span>Log Out</span>
                                    </div></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="rui-navbar-bg"
                    onClick={() => {
                        this.setState({
                            mobileMenuShow2:"none",
                        });

                    }}
                    onKeyUp={() => {  }}
                    role="button"
                    tabIndex={0}
                />
                <nav className={
                    classnames(
                        'rui-navbar rui-navbar-mobile ',
                        settings.nav_dark ? 'rui-navbar-dark' : '',
                        settings.nav_expand ? `rui-navbar-expand-${settings.nav_expand}` : '',
                        mobileMenuShow ? 'rui-navbar-show' : '',
                    )
                }
                >
                    <div className="rui-navbar-head">
                        {!mobileMenuShow ? (
                            <button
                                onClick={this.opensidebar} className="rui-yaybar-toggle rui-yaybar-toggle-inverse " type="button" aria-label="Toggle side navigation">
                                <span />
                            </button>
                        ) : ''}
                        {settings.nav_logo_path ? (
                            <span to={settings.nav_logo_url} className="rui-navbar-logo mr-auto">
                                <span style={{display:'inline-flex'}}>
                                    <img src={settings.night_mode || settings.nav_dark ? settings.nav_logo_white_path : settings.nav_logo_path} alt=""height={22} width={22} style={{marginTop:'3px'}} />
                                    <h2 style={{marginBottom:'0px',marginLeft:'5px',marginTop:'3px'}}>HRM</h2>
                                </span>

                            </span>
                        ) : ''}

                        <button
                            className="navbar-toggler rui-navbar-toggle ml-5"
                            onClick={() => {
                                this.setState((prevState) => ({
                                    mobileMenuShow: !prevState.mobileMenuShow,
                                    mobileMenuShow2: 'none'
                                }));

                            }}
                        >
                            <span />
                        </button>
                    </div>
                    <Collapse isOpen={mobileMenuShow} className="navbar-collapse rui-navbar-collapse">
                        <div className="rui-navbar-content">
                            {/* <ul className="nav"> */}
                            {/* <Nav className="flex-column"> */}
                            <div style={{ display: type2 == "employee" ? "block" : "none", width: '100%' }}>
                                <Nav className="flex-column">
                                    <NavItem className="dropss">
                                        <Dropdown
                                            tag="div"
                                            className="btn-group"
                                            openOnHover
                                            showTriangle
                                        >
                                            <Dropdown.Toggle tag="a" className="dropdown-item" >
                                                <Icon name="more-vertical " />
                                                <span >More</span>
                                                <span className="rui-dropdown-circle dropdown-item" />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu tag="ul" className="nav" modifiers={{ offset: { offset: '0,12' } }}>
                                                <li>
                                                    <div className="custom-control custom-switch dropdown-item-switch">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="toggleNightMode"
                                                            checked={settings.night_mode}
                                                            onChange={() => {
                                                                updateSettings({
                                                                    night_mode: !settings.night_mode,
                                                                });
                                                                //console.log("oooooooooooo", !settings.night_mode);
                                                                Cookies.set('night_mode_cookie', !settings.night_mode);
                                                                //console.log("Cookies.gt( 'night_mode_cookie')", Cookies.get('night_mode'));

                                                            }}
                                                        />

                                                        <label className="dropdown-item custom-control-label mylab" htmlFor="toggleNightMode">
                                                            <Icon name="moon" />
                                                            <span>Night Mode</span>
                                                            <span className="rui-dropdown-circle" />
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="custom-control custom-switch dropdown-item-switch">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="toggleSpotlightMode"
                                                            checked={settings.spotlight_mode}
                                                            onChange={() => {
                                                                updateSettings({
                                                                    spotlight_mode: !settings.spotlight_mode,
                                                                });
                                                            }}
                                                        />

                                                        <label className="dropdown-item custom-control-label mylab" htmlFor="toggleSpotlightMode">
                                                            <Icon name="square" />
                                                            <span>Spotlight Mode</span>
                                                            <span className="rui-dropdown-circle" />
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="custom-control custom-switch dropdown-item-switch">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="toggleSectionLines"
                                                            checked={settings.show_section_lines}
                                                            onChange={() => {
                                                                updateSettings({
                                                                    show_section_lines: !settings.show_section_lines,
                                                                });
                                                            }}
                                                        />

                                                        <label className="dropdown-item custom-control-label mylab" htmlFor="toggleSectionLines">
                                                            <Icon name="layout" />
                                                            <span>Show section lines</span>
                                                            <span className="rui-dropdown-circle" />
                                                        </label>
                                                    </div>
                                                </li>
                                                <li className="dropdown-menu-label">Sidebar</li>
                                                <li>
                                                    <div className="custom-control custom-switch dropdown-item-switch">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="toggleDarkSidebar"
                                                            checked={settings.sidebar_dark}
                                                            onChange={() => {
                                                                updateSettings({
                                                                    sidebar_dark: !settings.sidebar_dark,
                                                                });
                                                            }}
                                                        />

                                                        <label className="dropdown-item custom-control-label mylab" htmlFor="toggleDarkSidebar">
                                                            <Icon name="sidebar" />
                                                            <span>Dark</span>
                                                            <span className="rui-dropdown-circle" />
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="custom-control custom-switch dropdown-item-switch">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="toggleStaticSidebar"
                                                            checked={settings.sidebar_static}
                                                            onChange={() => {
                                                                updateSettings({
                                                                    sidebar_static: !settings.sidebar_static,
                                                                });
                                                            }}
                                                        />

                                                        <label className="dropdown-item custom-control-label mylab" htmlFor="toggleStaticSidebar">
                                                            <Icon name="sidebar" />
                                                            <span>Static</span>
                                                            <span className="rui-dropdown-circle" />
                                                        </label>
                                                    </div>
                                                </li>
                                                <li className="dropdown-menu-label">Navbar</li>
                                                <li>
                                                    <div className="custom-control custom-switch dropdown-item-switch">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="toggleDarkNavbar"
                                                            checked={settings.nav_dark}
                                                            onChange={() => {
                                                                updateSettings({
                                                                    nav_dark: !settings.nav_dark,
                                                                });
                                                            }}
                                                        />

                                                        <label className="dropdown-item custom-control-label mylab" htmlFor="toggleDarkNavbar">
                                                            <Icon name="menu" />
                                                            <span>Dark</span>
                                                            <span className="rui-dropdown-circle" />
                                                        </label>
                                                    </div>
                                                </li>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </NavItem>
                                </Nav>
                            </div>
                            <div style={{ display: type2 == "trainee" ? "block" : "none", width: '100%' }}>
                                <Nav className="flex-column">
                                    <NavItem className="dropss">
                                        <Dropdown
                                            tag="div"
                                            className="btn-group"
                                            openOnHover
                                            showTriangle
                                        >
                                            <Dropdown.Toggle tag="a" className="dropdown-item" >
                                                <Icon name="more-vertical " />
                                                <span >More</span>
                                                <span className="rui-dropdown-circle dropdown-item" />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu tag="ul" className="nav" modifiers={{ offset: { offset: '0,12' } }}>
                                                <li>
                                                    <div className="custom-control custom-switch dropdown-item-switch">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="toggleNightMode"
                                                            checked={settings.night_mode}
                                                            onChange={() => {
                                                                updateSettings({
                                                                    night_mode: !settings.night_mode,
                                                                });
                                                                //console.log("oooooooooooo", !settings.night_mode);
                                                                Cookies.set('night_mode_cookie', !settings.night_mode);
                                                                //console.log("Cookies.gt( 'night_mode_cookie')", Cookies.get('night_mode'));

                                                            }}
                                                        />

                                                        <label className="dropdown-item custom-control-label mylab" htmlFor="toggleNightMode">
                                                            <Icon name="moon" />
                                                            <span>Night Mode</span>
                                                            <span className="rui-dropdown-circle" />
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="custom-control custom-switch dropdown-item-switch">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="toggleSpotlightMode"
                                                            checked={settings.spotlight_mode}
                                                            onChange={() => {
                                                                updateSettings({
                                                                    spotlight_mode: !settings.spotlight_mode,
                                                                });
                                                            }}
                                                        />

                                                        <label className="dropdown-item custom-control-label mylab" htmlFor="toggleSpotlightMode">
                                                            <Icon name="square" />
                                                            <span>Spotlight Mode</span>
                                                            <span className="rui-dropdown-circle" />
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="custom-control custom-switch dropdown-item-switch">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="toggleSectionLines"
                                                            checked={settings.show_section_lines}
                                                            onChange={() => {
                                                                updateSettings({
                                                                    show_section_lines: !settings.show_section_lines,
                                                                });
                                                            }}
                                                        />

                                                        <label className="dropdown-item custom-control-label mylab" htmlFor="toggleSectionLines">
                                                            <Icon name="layout" />
                                                            <span>Show section lines</span>
                                                            <span className="rui-dropdown-circle" />
                                                        </label>
                                                    </div>
                                                </li>
                                                <li className="dropdown-menu-label">Sidebar</li>
                                                <li>
                                                    <div className="custom-control custom-switch dropdown-item-switch">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="toggleDarkSidebar"
                                                            checked={settings.sidebar_dark}
                                                            onChange={() => {
                                                                updateSettings({
                                                                    sidebar_dark: !settings.sidebar_dark,
                                                                });
                                                            }}
                                                        />

                                                        <label className="dropdown-item custom-control-label mylab" htmlFor="toggleDarkSidebar">
                                                            <Icon name="sidebar" />
                                                            <span>Dark</span>
                                                            <span className="rui-dropdown-circle" />
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="custom-control custom-switch dropdown-item-switch">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="toggleStaticSidebar"
                                                            checked={settings.sidebar_static}
                                                            onChange={() => {
                                                                updateSettings({
                                                                    sidebar_static: !settings.sidebar_static,
                                                                });
                                                            }}
                                                        />

                                                        <label className="dropdown-item custom-control-label mylab" htmlFor="toggleStaticSidebar">
                                                            <Icon name="sidebar" />
                                                            <span>Static</span>
                                                            <span className="rui-dropdown-circle" />
                                                        </label>
                                                    </div>
                                                </li>
                                                <li className="dropdown-menu-label">Navbar</li>
                                                <li>
                                                    <div className="custom-control custom-switch dropdown-item-switch">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="toggleDarkNavbar"
                                                            checked={settings.nav_dark}
                                                            onChange={() => {
                                                                updateSettings({
                                                                    nav_dark: !settings.nav_dark,
                                                                });
                                                            }}
                                                        />

                                                        <label className="dropdown-item custom-control-label mylab" htmlFor="toggleDarkNavbar">
                                                            <Icon name="menu" />
                                                            <span>Dark</span>
                                                            <span className="rui-dropdown-circle" />
                                                        </label>
                                                    </div>
                                                </li>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </NavItem>
                                </Nav>
                            </div>
                            <div style={{ display: type2 == "admin" ? "block" : "none", width: '100%' }}>
                                <Nav className="flex-column">
                                    <NavItem className="dropss">
                                        <Dropdown
                                            tag="div"
                                            className="btn-group"
                                            openOnHover
                                            showTriangle
                                        >
                                            <Dropdown.Toggle tag="a" className="dropdown-item">
                                                <Icon name="more-vertical" />
                                                <span>More</span>
                                                <span className="rui-dropdown-circle" />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu tag="ul" className="nav" modifiers={{ offset: { offset: '0,12' } }}>
                                                <li>
                                                    <div className="custom-control custom-switch dropdown-item-switch">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="toggleNightMode"
                                                            checked={settings.night_mode}
                                                            onChange={() => {
                                                                updateSettings({
                                                                    night_mode: !settings.night_mode,
                                                                });
                                                                //console.log("oooooooooooo", !settings.night_mode);
                                                                Cookies.set('night_mode_cookie', !settings.night_mode);
                                                                //console.log("Cookies.gt( 'night_mode_cookie')", Cookies.get('night_mode'));

                                                            }}
                                                        />

                                                        <label className="dropdown-item custom-control-label mylab" htmlFor="toggleNightMode">
                                                            <Icon name="moon" />
                                                            <span>Night Mode</span>
                                                            <span className="rui-dropdown-circle" />
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="custom-control custom-switch dropdown-item-switch">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="toggleSpotlightMode"
                                                            checked={settings.spotlight_mode}
                                                            onChange={() => {
                                                                updateSettings({
                                                                    spotlight_mode: !settings.spotlight_mode,
                                                                });
                                                            }}
                                                        />

                                                        <label className="dropdown-item custom-control-label mylab" htmlFor="toggleSpotlightMode">
                                                            <Icon name="square" />
                                                            <span>Spotlight Mode</span>
                                                            <span className="rui-dropdown-circle" />
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="custom-control custom-switch dropdown-item-switch">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="toggleSectionLines"
                                                            checked={settings.show_section_lines}
                                                            onChange={() => {
                                                                updateSettings({
                                                                    show_section_lines: !settings.show_section_lines,
                                                                });
                                                            }}
                                                        />

                                                        <label className="dropdown-item custom-control-label mylab" htmlFor="toggleSectionLines">
                                                            <Icon name="layout" />
                                                            <span>Show section lines</span>
                                                            <span className="rui-dropdown-circle" />
                                                        </label>
                                                    </div>
                                                </li>
                                                <li className="dropdown-menu-label">Sidebar</li>
                                                <li>
                                                    <div className="custom-control custom-switch dropdown-item-switch">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="toggleDarkSidebar"
                                                            checked={settings.sidebar_dark}
                                                            onChange={() => {
                                                                updateSettings({
                                                                    sidebar_dark: !settings.sidebar_dark,
                                                                });
                                                            }}
                                                        />

                                                        <label className="dropdown-item custom-control-label mylab" htmlFor="toggleDarkSidebar">
                                                            <Icon name="sidebar" />
                                                            <span>Dark</span>
                                                            <span className="rui-dropdown-circle" />
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="custom-control custom-switch dropdown-item-switch">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="toggleStaticSidebar"
                                                            checked={settings.sidebar_static}
                                                            onChange={() => {
                                                                updateSettings({
                                                                    sidebar_static: !settings.sidebar_static,
                                                                });
                                                            }}
                                                        />

                                                        <label className="dropdown-item custom-control-label mylab" htmlFor="toggleStaticSidebar">
                                                            <Icon name="sidebar" />
                                                            <span>Static</span>
                                                            <span className="rui-dropdown-circle" />
                                                        </label>
                                                    </div>
                                                </li>
                                                <li className="dropdown-menu-label">Navbar</li>
                                                <li>
                                                    <div className="custom-control custom-switch dropdown-item-switch">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="toggleDarkNavbar"
                                                            checked={settings.nav_dark}
                                                            onChange={() => {
                                                                updateSettings({
                                                                    nav_dark: !settings.nav_dark,
                                                                });
                                                            }}
                                                        />

                                                        <label className="dropdown-item custom-control-label mylab" htmlFor="toggleDarkNavbar">
                                                            <Icon name="menu" />
                                                            <span>Dark</span>
                                                            <span className="rui-dropdown-circle" />
                                                        </label>
                                                    </div>
                                                </li>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </NavItem>
                                </Nav>
                            </div>
                            {/* </Nav> */}
                            {/* </ul> */}
                        </div>
                    </Collapse>
                </nav>
                <div
                    className="rui-navbar-bg"
                    onClick={() => {
                        this.setState({
                            mobileMenuShow: !mobileMenuShow,
                        });
                        document.getElementById("idname").style.width = "0";
                    }}
                    onKeyUp={() => { }}
                    role="button"
                    tabIndex={0}
                />

                    <Modal
                        isOpen={ this.state.toggleEmojii }
                        toggle={ this.toggleEmojii }
                        className={ this.props.className,"modal-dialog-centered log_out_model"  }
                        fade
                    >
                       <ModalBody className=" my_show_row">
                            <div className="row">
                            <div className="col-lg-12">
                               <h3 className="how_you_feel"style={{marginBottom:"6px",fontWeight:"700",marginTop:"5px"}}>Instructions</h3>  
                            </div>
                            <div className="col-lg-12">
                                {this.state.logout_instruction_array.map((val,index)=>{
                                    return(
                                        <div key={index} className="new_changes_in">
                                          {index + 1}.  <p dangerouslySetInnerHTML={{ __html: val.instruction }} className="message_new"></p>
                                        </div>
                                    )
                                })} 
                            </div>
                            <div className="col-lg-12">
                               <h3 className="how_you_feel" style={{marginBottom:"2px",marginTop:"15px",fontWeight:"700"}}>How Was Your Day</h3>  
                            </div>
                            </div>



                            <div className="emojii_data_all">
                            <div className="text_center_new happy">
                            <div aria-hidden="true" onClick={()=>{
                                        this.setState({
                                            emoji_data:"happy"
                                        })
                                    }}>
                              <p  className="foont_sixe">&#128516;</p>
                              <Button outline color="primary"
                              style={{backgroundColor:this.state.emoji_data == "happy" ? "#007bff" :"",borderColor:this.state.emoji_data == "happy" ? "#007bff" :"",color:this.state.emoji_data == "happy" ? "#fff" :""}} 
                               className="my_btn_new">Happy</Button>
                              {/* <Label>Happy</Label> */}
                           </div>
                           </div>

                           <div className="text_center_new enthusiatic">
                            <div aria-hidden="true" onClick={()=>{
                                        this.setState({
                                            emoji_data:"enthusiatic"
                                        })
                                    }}>
                                <p  className="foont_sixe">&#128525;</p>
                                <Button outline color="primary" 
                                style={{backgroundColor:this.state.emoji_data == "enthusiatic" ? "#007bff" :"",borderColor:this.state.emoji_data == "enthusiatic" ? "#007bff" :"",color:this.state.emoji_data == "enthusiatic" ? "#fff" :""}}
                                className="my_btn_new">Enthusiatic</Button>
                                {/* <Label>Enthusiatic</Label> */}
                            </div>
                            </div>

                            <div className="text_center_new normal">
                            <div aria-hidden="true" onClick={()=>{
                                        this.setState({
                                            emoji_data:"normal"
                                        })
                                    }}>
                              <p  className="foont_sixe">&#128578;</p>
                              <Button outline color="primary" 
                              style={{backgroundColor:this.state.emoji_data == "normal" ? "#007bff" :"",borderColor:this.state.emoji_data == "normal" ? "#007bff" :"",color:this.state.emoji_data == "normal" ? "#fff" :""}}
                              className="my_btn_new">Normal</Button>
                              {/* <Label>Normal</Label> */}
                           </div>
                           </div>

                            <div className="text_center_new">
                                <div aria-hidden="true" onClick={()=>{
                                    this.setState({
                                        emoji_data:"lazy"
                                    })
                                }}>
                                <p className="foont_sixe lazy">&#128564;</p>
                                <Button outline color="primary" className="my_btn_new" style={{backgroundColor:this.state.emoji_data == "lazy" ? "#007bff" :"",borderColor:this.state.emoji_data == "lazy" ? "#007bff" :"",color:this.state.emoji_data == "lazy" ? "#fff" :""}}>Lazy</Button>
                                {/* <Label>Lazy</Label> */}
                               </div>
                           </div>

                            <div className="text_center_new sad">
                                <div aria-hidden="true" onClick={()=>{
                                        this.setState({
                                            emoji_data:"sad"
                                        })
                                    }}>
                                <p  className="foont_sixe">&#128532;</p>
                                <Button outline color="primary"
                                 style={{backgroundColor:this.state.emoji_data == "sad" ? "#007bff" :"",borderColor:this.state.emoji_data == "sad" ? "#007bff" :"",color:this.state.emoji_data == "sad" ? "#fff" :""}}
                                 className="my_btn_new">Sad</Button>
                                {/* <Label>Sad</Label> */}
                            </div>
                           </div>                               
                                <div className="text_center_new angry">
                                <div aria-hidden="true" onClick={()=>{
                                            this.setState({
                                                emoji_data:"sick"
                                            })
                                        }}>
                                <p  className="foont_sixe">&#129298;</p>
                                <Button outline color="primary" 
                                style={{backgroundColor:this.state.emoji_data == "sick" ? "#007bff" :"",borderColor:this.state.emoji_data == "sick" ? "#007bff" :"",color:this.state.emoji_data == "sick" ? "#fff" :""}}
                                className="my_btn_new">Sick</Button>
                                {/* <Label>Angry</Label> */}
                            </div>
                            </div>
                            <div className="text_center_new angry">
                                <div aria-hidden="true" onClick={()=>{
                                            this.setState({
                                                emoji_data:"angry"
                                            })
                                        }}>
                                <p  className="foont_sixe">&#128544;</p>
                                <Button outline color="primary" 
                                style={{backgroundColor:this.state.emoji_data == "angry" ? "#007bff" :"",borderColor:this.state.emoji_data == "angry" ? "#007bff" :"",color:this.state.emoji_data == "angry" ? "#fff" :""}}
                                className="my_btn_new">Angry</Button>
                                {/* <Label>Angry</Label> */}
                            </div>
                            </div>
                            </div>
                       
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={()=>{
                                this.setState({
                                    toggleEmojii:false 
                                })
                            }}>Close</Button>
                            { ' ' }
                            <Button color="brand" onClick={ this.add_log_out_mood }>Logout</Button>
                        </ModalFooter>
                    </Modal>
            </>
        );
    }
}

export default connect(({ settings }) => (
    {
        settings,
    }
), {
    updateAuth: actionUpdateAuth,
    updateSettings: actionUpdateSettings,
})(PageNavbar);
